.answer-btn {
    border: none;
    background-color: transparent;
    font-size: 35px;
    color: #f7896b;
}
.q-a {
    background-color: #e6e6e6;
    border-radius: 50px;
    box-shadow: 5px 5px 5px 5px #020108;
    padding-bottom: 20px;
}

.q-a-title {
    color: #2d1e58;
    margin-top: 20px !important;
    margin-left: 20px !important;
}

.question {
    margin-left: 10%;
    margin-right: 10%;
    color: #201f38;
    font-weight: 900;
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 30px;
    border-top: 1px solid #6464a0;
}

@media screen and (max-width: 1000px) {
    .question {
        margin-left: 0px !important;
    }
}

.question-6 {
    border-bottom: 1px solid #6464a0;
}

.show-question {
    margin-left: 5%;
    margin-right: 5%;
    background-color: #6464a0;
    border-radius: 35px;
    transition: .5s;
}

.next-question {
    border-top: 0px !important;
}

.show-question .answer-btn {
    display: none;
}
.answer-1 {
    display: none;
}

.answer-2 {
    display: none;
}

.answer-3 {
    display: none;
}

.answer-4 {
    display: none;
}

.answer-5 {
    display: none;
}

.answer-6 {
    display: none;
}

.show-answer {
    display: inline-block !important;
    color: #e6e6e6;
    font-weight: 300;
    padding: 3%;
    font-size: 20px !important;
}