.responseTable {
    padding-bottom: 40%;
}

.adminResponse {
    overflow: hidden;
}

.clientCard {
  padding: 5%;
  margin: 5%;
  box-shadow: 0 1px 5px 0 #293241;
  color: #293241;
}

table {
    display: block;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin: 0 auto;
    overflow-x: auto;
    white-space: nowrap;
  }

  .scrollTable tr {
    margin: 0px 12px 0px 0px;
    align-items: center;
    text-align: center;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%),
      0 1px 5px 0 rgb(0 0 0 / 20%);
    color: #293241;
  }