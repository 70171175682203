.service-card-row-1 {
    display: none;
}

.service-card-row-2 {
    display: none;
}

.service-card-row-3 {
    display: none;
}

.show-graphic-cards {
    display: flex !important;
}

.graphic-service-btn {
    font-size: 50px;
    margin-bottom: 5%;
    margin-top: 2%;
    font-weight: 900;
    color: #b2b2cc !important;
    transition: 1s !important;
}

.graphic-service-btn:focus {
    box-shadow: none !important;
}

.graphic-service-card {
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 5%;
    border: dashed 1px rgb(0, 0, 27);
    border-radius: 10px;
    padding: 8% 8% 8% 8%;
    box-shadow: 1px 3px 3px rgb(0, 0, 27);
    background-color: #b2b2cc !important;
}

.graphic-service-card-title {
    color: #201f38 !important;
    font-weight: 900;
    margin-bottom: 8%;
}

.graphic-service-card-text {
    color: #201f38 !important;
    font-weight: 300;
    margin-bottom: 5%;
}

.graphic-service-card-row-bottom {
    margin-top: 10%;
}

.graphic-service-card-price {
    color: #201f38 !important;
    font-weight: 900;
    font-size: 25px;
}

.btn-light {
    background-color: #201f38 !important;
}

.btn-light-text {
    color: #f7896b !important;
    text-decoration: none !important;
}

.service-cards-row .row>*{
    padding-right: 0 !important;
}
 .service-cards-row {
        margin-left: 0 !important;
       
    }
@media screen and (max-width: 400px) {
    .graphic-service-btn {
        font-size: 25px;
    }
}

.graphic-page-img {
    width: 80% !important;
}

.graphic-orange {
    color: #f7896b !important;
    font-weight: 300;
    font-size: 18px;
    padding: 3% 8% 8% 8%;
    text-shadow:1px 1px 1px #201f38;
}