.mobile-ui {
    height: 800px;
}

@media screen and (max-width: 1200px) {
    .mobile-iphone {
        margin-left: 0px !important;
    }
    iframe.mobile-iframe {
        margin-left: 90px !important;
    }
}


@media screen and (max-width: 767px) {
    .mobile-ui {
        display: none;
    }
}

.mobile-iphone {
    z-index: 1 !important;
    position: absolute;
    height: 800px;
    margin-left: 100px;
}

iframe.mobile-iframe {
    border-radius: 35px;
    margin-top: 20px;
   display: flex;
   margin-left: 190px;
   height: 735px;
   z-index: 2 !important;
   position: absolute;
   width: 350px;
}