.StripeElement {
    height: 40px;
    padding: 10px 12px;
    width: 100%;
    color: #32325d;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 4px;

    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
  }
  
  .StripeElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
  }
  
  .StripeElement--invalid {
    border-color: #fa755a;
  }
  
  .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }

  .MuiCard-root {
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

.clientIframe {
  height: 600px !important;
  width: 90%;
  margin: 5% !important;
  
}

.client-page {
  margin-top: 10%;
  margin-bottom: 10%;
}