.cookieToast {
  opacity: .7 !important;
  z-index: 1030 !important;
  position: fixed !important;
  bottom: 0;
  padding: 0 !important;
  width: 100% !important;
  transition-duration: 1s;
}

.cookieToast.closeThis {
  opacity: 0 !important;
  z-index: -1 !important;
}

.toast-text p {
  text-align: center;
  margin-bottom: 0rem !important;
  font-size: 15px;
}

.btn-accept {
  color: #e6e6e6;
  background-color: #201241;
  border-radius: 25px;
  font-size: 12px;
}

.properly {
  transition-duration: 0s !important;
}


@media screen and (max-width:600px) {

  .toast-text p {
    font-size: 10px !important;
  }

  .btn-accept {
    font-size: 10px;
  }
}

@media screen and (max-width:600px) {


  .toast-text p {
    font-size: 8px;
  }

  .btn-accept {
    font-size: 8px;
  }
}
