@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@100;600;900&display=swap');

*{
    font-family: 'Roboto', sans-serif;
}

body {
    background-color: #e6e6e6 !important;
}

body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden !important; 
}

/* body .show{
    overflow-x: hidden;
    background: linear-gradient( rgba(34, 33, 63, 0.7), rgba(16, 16, 27, 0.7) ), url("https://cdn.pixabay.com/photo/2016/10/30/20/22/astronaut-1784245_1280.jpg") !important;
} */


.nav-link {
    font-size: 20px;
    color: #e6e6e6 !important;
    transition: 1s !important;
}

.nav-link:hover {
    color: #f7896b !important;
}

li.nav-item {
    position: relative;
    display: inline-block;
  }
  li.nav-item:after {
    content: "";
    position: absolute;
    left:0;
    top: 100%;
    width: 0;
    height: 4px;
    background-color: #f7896b;
    transition: width .5s ease-in-out;
  }
  li.nav-item:hover:after {
    width: 100%;
  }

.show .nav-item {
    font-size: 20px;
}

.show .nav-link {
    font-size: 20px;
}

.payment-card {
    margin: 20%;
}
.clientsName {
   margin-top: 5px;
    color: #e6e6e6;
    margin-left: -10% !important;
    margin-right: 10% !important;
}

.clientsName:hover {
    color: #f7896b;
}
.newUser {
    padding: 5%;
    margin-left: 10% !important;
    margin-right: 10% !important;
    margin-bottom: 250px;
}

.logoutBtn {
    border: none;
    border-radius: 15px;
    padding: 5px 15px 5px 15px;
    color: #201f38;
}

.logoutBtn:hover {
    background-color: #201f38;
    color: #e6e6e6;
}

.copyrightIcon {
    padding-top: 15px;
}
.login-block {
    padding: 5%;
    margin-bottom: 350px;
    margin-top: 50px;
    background: white;
}
.example {
    margin: 3%;
    width: 260px;
    height: 200px;
}
.astroForm {
    background-color: #e6e6e6 !important;
    border-bottom: solid;
    border-top: none;
    border-left: none;
    border-right: none;
    border-color: #201f38;
    padding: 1%;
}

.astroForm-white {
    background-color: white !important;
    border-bottom: solid;
    border-top: none;
    border-left: none;
    border-right: none;
    border-color: #201f38;
    padding: 1%;
}
.form {
    padding-right: 8%;
    padding-left: 8%;
}
.form-control:focus {
    box-shadow: none !important;
    border-color: #f7896b;
}

.astroCheck {
    background-color: #e6e6e6;
    border-color: #201f38;
    border-width: 2px;
}

.form-check-input:checked {
    background-color: #f7896b;
    border-color: #f7896b;
    
}

.sub-btn {
    position: relative;
    color: #e6e6e6;
    font-weight: 300;
    background-color: #201f38;
    border-radius: 30px;
    text-decoration: none;
    transition-duration: .5s;
}

.account-viewer {
    padding-top: 150px;
    padding-bottom: 400px;
}

.account-viewer td {
    padding: 5%;
}

.astroChoose {
    background-color: #e6e6e6;
    border-color: #201f38;
    border-width: 2px;
}

.background-purple {
    background-color: #201f38;
}

.background-orange {
    background-color: #f7896b;
}

.background-clear {
    background: linear-gradient( rgba(34, 33, 63, 1), rgba(16, 16, 27, 0.0) )
}

.background-home {
    background: linear-gradient( rgba(32, 31, 56, 0.5), rgba(32, 31, 56, 0.7) ), url("https://cdn.pixabay.com/photo/2016/10/30/20/22/astronaut-1784245_1280.jpg");
    background-size: cover;
    padding-top: 20%;
    padding-bottom: 4%;
}

.background-webDev {
    background: linear-gradient( rgba(32, 31, 56, 0.7), rgba(32, 31, 56, 0.9) ), url("https://i.pinimg.com/originals/f9/ba/36/f9ba36b9b4c89ea9a57a6a18a96d0a0a.jpg");
    background-size: cover;
    padding-top: 20%;
    padding-bottom: 17%;
}

.background-Graphic {
    background: linear-gradient( rgba(32, 31, 56, 0.7), rgba(32, 31, 56, 0.9) ), url("https://us.123rf.com/450wm/primagefactory/primagefactory1710/primagefactory171000823/88694580-selective-focus-photo-of-graphic-designer-using-digital-pad-pen-work-.jpg?ver=6");
    background-size: cover;
    padding-top: 20%;
    padding-bottom: 20%;
}

.background-socialMedia {
    background: linear-gradient( rgba(32, 31, 56, 0.7), rgba(32, 31, 56, 0.9) ), url("https://media2.govtech.com/images/940*617/social+media+apps1.jpg");
    background-size: cover;
    padding-top: 20%;
    padding-bottom: 12%;
}

.background-Ads {
    background: linear-gradient( rgba(32, 31, 56, 0.7), rgba(32, 31, 56, 0.9) ), url("https://wtmarketingpros.com/wp-content/uploads/2018/12/Optimized-PPC.jpg");
    background-size: cover;
    padding-top: 20%;
    padding-bottom: 23%;
}
.checkServices {
    text-align: left !important;
}
.btn:hover {
    color: #f7896b !important;
}

.about-page-outer {
    padding-top: 15%;
    padding-bottom: 15%;
}


.background-e6 {
    background-color: #e6e6e6;
}

.blue {
    color: #6464a0 !important; 
    font-weight: 500;
}

.purple {
    color: #201f38;
    font-weight: 500 !important;
}

.orange {
    color: #f7896b !important;
    font-weight: 500;
}

.orange-clear {
    color: rgb(172, 114, 7) !important;
}

.astrologo {
    width: 250px;
    padding-left: 10px !important;
}

.astrologo-admin {
    width: 100px;
    padding-left: 10px !important;
}

.about-page {
    margin-bottom: 5%;
}

.aboutLogo {
    width: 392px;
    height: 320px;
    margin-top: 5%;
    margin-bottom: 5%;
}

.about-text {
    padding-left: 10%;
    padding-right: 10%;
    color: #3d3c6e;
    font-size: 18px;
    font-weight: 900;
}

.about-title {
    margin-top: 5%;
    margin-bottom: 5%;
    color: #6464a0 ;
}

.about-ceo {
    margin-top: 5%;
    margin-top: 5%;
    color: #3d3c6e;
    font-size: 18px;
    font-weight: 900;
}




.card-bs {
    width: stretch;
}

.contact-card {
    margin-top: 10%;
}

.graphic {
   
    width: 60% !important;
    height: 100% !important;
    padding: 5%;
    transition-duration: .5s; 
}

.graphic:hover {
    width: 70% !important;
    height: 100% !important;
}

.learn-more {
    position: relative;
    font-size: 15px;
    color: #e6e6e6;
    font-weight: 300;
    background-color: #f7896b;
    padding: 3%;
    margin-left: 25%;
    margin-right: 25%;
    width: 50%;
    border-radius: 30px;
    text-decoration: none;
    bottom: 25px;
    transition-duration: .5s;
}
.learn-more:hover {
    padding: 15px 30px;
    color: #6464a0;
    font-weight: 500;
    font-size: 17px;

}

.services-bg {
    background-color: #201f38;
}
.skill-card {
    text-align: center;
    padding-bottom: 5%;
    width: 100%;
    overflow-y: hidden;
}

.service-text-row {
    padding-right: 5%;
    color: #f7896b ;
}

.service-text-row-right {
    color: #f7896b ;
    padding-left: 5%;
}

.service-text-p {
    margin-top: 5%;
    color: #b2b2cc;
    font-weight: 300 !important;
}

@media screen and (max-width: 770px) {
    .service-text-title {
        margin-top: 10%;
    }
}
.card-img {
    text-align: center;
}

.card-name {
    text-align: center;
    padding-top: 8%;
}

.card-btn {
    text-align: center;
    padding: 12%;
    font-size: 13px;
    margin-top: 15px;
}
#icon {
    width: 35px;
    height: auto;
    display: flex;
}

.contact {
    background-color: rgba(216, 216, 216, 0.65);
    margin-top: 15%;
    padding: 10%;
    margin-bottom: 250px;
}

.bigtxt1 {
    font-family: 'Work Sans', sans-serif;
    color: #6464a0;
    font-weight: 900;
    font-size: 150px;
    
    padding-top: 100px;  
}

.web-sub {
    color: #201f38 ;
    padding-left: 10% !important;
    padding-right: 10% !important;
}

.web-sub-text {
    color: #b2b2cc;
    padding-left: 10% !important;
    padding-right: 10% !important;
    padding-bottom: 5% !important;
}

.landing-row {
    margin-top: 5%;
}



.web-btn {
    list-style-type: none;
    margin-top: -5% !important;
}

.why-social-title {
    color: #f7896b;
    font-weight: 900;
    padding: 0% 5% 5% 5%;
}

.why-social-sub {
    color: #b2b2cc;
    font-weight: 300;
    font-size: 18px;
    padding-right: 5%;
}

.astroLogo-ul {
    list-style-type: none;
}

.social-graphic-title {
    color: #b2b2cc;
    font-weight: 900;
    font-size: 35px;
    padding-bottom: 10%;
}

.social-graphic-text {
    color: white;
    font-weight: 900;
    font-size: 22px;
    margin-bottom: 150px;
    margin-top: 70px;
    z-index: 10 !important;
}


.socialmedia-bottom {
    color: #b2b2cc;
    font-size: 30px;
    font-weight: 900;
    padding-right: 5%;
}

.socialmedia-bottom-text {
    color: #e6e6e6;
    font-size: 20px;
    padding-right: 5%;
}

.socialmedia-bottom-row {
    overflow: visible !important;
}

@media screen and (max-width: 1200px){
    .bigtxt1 {
        font-size: 100px;  
    }
    .why-social-sub {
        margin-top: 10%;
        padding-left: 5%;
    }
    .socialmedia-bottom-text {
        padding-left: 5%;
    }
    .socialmedia-bottom {
        padding-left: 5%;
    }
}

@media screen and (max-width: 800px){
    .bigtxt1 {
        font-size: 80px;  
    }
    .landing-row {
        margin-left: 0px !important;
    }
    .sched-text-top {
        padding-left: 0px !important;
    }
    .sched-text-bottom {
        padding-left: 0px !important;
    }
    .ordering-btn {
        padding-left: 0px !important;
        margin-left: 0px !important;
    }
}

@media screen and (max-width: 400px){
    .bigtxt1 {
        font-size: 60px;  
    }
}

.scenario-title {
    padding-top: 5%;
    color: #201f38;
    font-weight: 900;
    padding-left: 5%;
    font-size: 40px;
}

.sched-title {
    margin-top: 60px;
    color: #6464a0;
    font-size: 40px;
}

.sched-text {
    color: #b2b2cc;
    font-size: 20px;
    padding-left: 10%;
    padding-right: 10%;
}

.sched-title-right {
    margin-top: 60px;
    color: #6464a0;
    font-size: 40px;
}

.sched-text-right {
    color: #b2b2cc;
    font-size: 20px;
    padding-left: 10%;
    padding-right: 10%;
}

.sched-text-top {
    color: #b2b2cc;
    font-size: 20px;
    padding-left: 10%;
    padding-right: 10%;
}

.sched-text-bottom {
    color: #f7896b;
    font-size: 20px;
    padding-left: 10%;
    padding-right: 10%;
}



.sc-card {
    font-size: 20px;
    font-weight: 900;
    padding: 15% 10% 5% 10%;
    height: 200px;
    background-color: #6464a0;
    color: #b2b2cc;
    margin: 5% 1% 5% 1%;
    border-radius: 10px;
}

.sc-row {
    margin: 0% 1% 0% 1%;
}

.service-landing {
    padding-top: 200px;
    padding-bottom: 300px;
    list-style-type: none;
    padding-left: 0px !important;
}

.web-ss-row {
    padding-top: 5%;
    padding-bottom: 5%;
   background-color: #e6e6e6;
}

.web-ss {
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
}

.website-content {
    background-color: #6464a0;
    padding-top: 5%;
    padding-bottom: 5%;
}
.website-content-2 {
    background-color: white;
    padding-top: 5%;
    padding-bottom: 5%;
}
.website-content-3 {
    background-color: #201f38;
    padding-top: 5%;
    padding-bottom: 5%;
}
.website-content-4 {
    background-color: #434275;
    padding-top: 5%;
    padding-bottom: 5%;
}
.website-content-5 {
    background-color: #302f55;
    padding-top: 5%;
    padding-bottom: 5%;
}

@media screen and (max-width:600px) {
    .web-ss-div {
        padding-right: 0px !important;
        padding-top: 10px;
    }
    .web-ss {
        width: 80% !important;
        padding-right: 0px !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
        margin-bottom: 20px;
    }
}

.mobile-title {
    margin-left: 50px;
    color: #f7896b ;
    font-size: 60px;
    font-weight: 900;
}

.mobile-text {
    color: #e6e6e6;
    margin-left: 50px;
    font-Size: 20px;
}

.mobile-row {
  padding-left: 50px;
  margin-top: 20px;
}

.mobile-div {
    margin-left: 0px !important;
}

.mobile-ss {
    width: 100% !important;
    border-radius: 15px;
}

@media screen and (max-width: 600px) {
    .mobile-content {
     padding-left: 0px !important;
    }
    .mobile-row {
        padding-left: 40px !important;
    }
    .mobile-ss {
        padding-bottom: 20px !important
    }
}

.quoteRecBtn {
    text-align: center;
    padding-top: 0.5%;
    text-decoration: none;
    width: 200px;
    height: 45px;
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
    color: #e6e6e6;
    background-color: #f7896b;
    border: 5px white;
    /* position: relative;
    bottom: 190px; 
    left: 50px;   */
    position: relative;
    margin-bottom: 100px !important;
    border-bottom: 5px solid transparent;
    border-radius: 50px;
    padding: 10px 25px;
    transition-duration: 0.4s;
}
.quoteRecBtn:hover {
    border-bottom: 5px solid #6464a0;
    color: #6464a0;
    width: 210px;
    height: 50px;
}
.startedBtn {
    text-align: center;
    padding-top: 0.5%;
    text-decoration: none;
    width: 200px;
    height: 45px;
    font-family: 'Work Sans', sans-serif;
    font-weight: 600;
    color: #e6e6e6;
    background-color: #f7896b;
    border: 5px white;
    
    
    border-bottom: 5px solid transparent;
    border-radius: 50px;
    padding: 10px 25px;
    transition-duration: 0.4s;
}
.startedBtn:hover {
    border-bottom: 5px solid #6464a0;
    color: #6464a0;
    width: 210px;
    height: 50px;
}
.service-title-text{
    font-weight:900; 
    text-align: center;
    padding: 70px;
    color: #6464a0;
}

.home-form {
    padding-bottom: 8%;
    padding-top: 5%;
}

.text-astro {
    color: #e6e6e6;
}

.services-nav {
    border: none;
}

.navbar-toggler {
    background-color: transparent;
    z-index: 10;
    border: 0px !important;
}

.navbar-collapse.show {
    background-color: #212529 !important;
    
    margin-top: -100px !important;
    padding-top: 100px !important;
    padding-bottom: 500px;
    
}

.navbar-collapse {
    transition: .5s !important;
   
}
.show .navbar-nav {
   top: -200px !important;
   
}

.dropdown-menu.show {
    background-color: #212529;
    
}

.container-fluid{
    padding: 0% !important;

}
.word {
    font-weight: bold;
    font-style: normal;
}

.picture-page {
    background-color: #272727 !important;
    overflow-x: hidden;
  }

  .column {
    flex: 25%;
    max-width: 25%;
    padding: 0 4px;
  }
  
  .column img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
  }
  
  .column-margin {
    padding-bottom: 100px !important;
    padding-top: 50px !important;
    font-family: 'Times New Roman', Times, serif;
    font-style: italic;
    color: #e8dbc4;
  }
  
  /* Responsive layout - makes a two column-layout instead of four columns */
  @media screen and (max-width: 800px) {
    .column {
      flex: 50%;
      max-width: 50%;
    }
  }
  
  /* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
  @media screen and (max-width: 600px) {
    .column {
      flex: 100%;
      max-width: 100%;
    }
  }

.pictures-map.row {
    display: flex;
    flex-wrap: wrap;
    padding: 0 4px;
  }

.page-img {
    width: 25%;
  }

.pictures-logo-text {
    margin-top: 10%;
    color: #f7896b;
    font-weight: 900 !important;
    font-size: 50px;
}

.pictures-subtitle {
    color: #201f38;
    font-weight: 900 !important;
    font-size: 16px;
    text-shadow: 2px 2px 2px #e6e6e6;
}

.pictures-bottom-text {
    margin-top: 10%;
    color: #f7896b;
    font-weight: 900 !important;
    font-size: 50px;
}


.dropdown-menu {
    border: none !important;
  box-shadow: none !important;
}
.dropdown .btn:focus {
    outline: none !important;
  box-shadow: none !important;
}
.whyAstro {
    margin-top: -100px;
    background-color: #b2b2cc;
    padding-bottom: 100px;
    padding: 5% !important;
    margin-bottom: 100px;
}
.responseContainer {
    background-color: #6464a0;
    padding-top: 5%;
    padding-left: 10%;
    padding-right: 10%;
    padding-bottom: 10%;
}
.responseLogo {
    margin-top: 8%;
    height: 125px;
    width: 500px;
}
.responseBtn {
    width: 20%
}
.foot {
    padding-bottom: 4%;
}
.orange-foot {
    color: #f7896b;
}
.btn-foot:hover {
    color: white !important;
}
.footerlogo {
    margin-top: 5% !important;
    margin-bottom: 5% !important;
    width: 400px;
    height: 61px;
}
.footer{
    padding-top: 6px;
    padding-bottom: 6px;
    background-color: #201f38;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    border-top: solid;
    border-width: 6px;
    border-top-color: #e6e6e6;
    outline: solid;
    outline-color: #f7896b;
    outline-width: 2px;
  }

  .response-text {
      font-size: 40px;
      padding: 200px 40px 20px 40px;
      font-weight: 900;
      color: #f7896b;
  }

  .response-btn {
        background-color: #f7896b;
        padding: 1% 4% 1% 4%;
        font-size: 18px;
        color: #6464a0;
        border-radius: 20px;
        font-weight: 900;
  }

  .response-btn:hover {
      border: 2px solid #6464a0;
  }

  @media screen and (max-width: 700px) {
    .response-text {
        font-size: 30px;
        padding: 200px 20px 20px 20px;
        font-weight: 900;
        color: #f7896b;
    }

    .response-btn {
        background-color: #f7896b;
        padding: 2% 6% 2% 6%;
        font-size: 18px;
        color: #6464a0;
        border-radius: 20px;
  }
}


@media screen and (max-width: 1000px) {

    .clientsName {
        margin-left: 0% !important;
        margin-right: 0% !important;
    }
    .astrologo {
        width: 200px;

    }

    .copyrightIcon {
        padding-bottom: 15px;
    }

    .login-block {
        margin-bottom: 420px;
    }
    .graphic {
        width: auto;
        height: 165px;
        transition-duration: .5s;
        padding: 5%;
        overflow-x: visible;
    }
    .graphic:hover {
       height: 185px;
    }
    
    .footerlogo {
        width: 250px;
        height: 36px;
    }
    .responseContainer {
        padding-top: 20%;
        padding-left: 10%;
        padding-right: 10%;
        padding-bottom: 40%;
    }
    .responseLogo {
        margin-top: 5%;
        height: 75px;
        width: 300px;
    }
    .responseBtn {
        width: 30%
    }
    .form {
        padding-right: 5%;
        padding-left: 15%;
    }
    .homeastroimg {
        width: 300px;
        height: auto;
        transform: translate3d(-8%,110%,50px);
    }
    .nav-item {
        align-self: center;
    }
    .dropdown {
        padding-top: 3%;
    }
    .dropdown-menu.show {
        text-align: center !important;
    }
    .dropdown::after {
        justify-content: center !important;
    }
    .navbar {
        width: 100%;
        position: sticky;
        z-index: 999 !important;
        margin-right: 0!important;
        margin-left: 0 !important;
        overflow-x: hidden;
    }
    .footer{
        z-index: 999 !important;
        margin-right: 0 !important;
        margin-left: 0 !important;
        overflow-x: hidden;
    }
    .foot {
        padding-left: 8% !important;
    }
   
    .orange-foot {
        font-size: 8px;
        padding-left: 0 !important;
        padding-right: 0 !important;
        font-weight: bold;
    }
    .tilt-logo {
        transform: translate3d(6%,0,50px);
    }
    .h1-text {
        margin-left: 3%;
    }
    .home-box {
        text-align: center;
    }

    
    
    
    
    .row {
        margin-right: 0 !important;
        overflow-x: hidden;
    }
    .purple-row {
        margin-left: 0 !important;
    }
    .services-bg {
        padding-left: 8%;
    }
    #icon {
        width: 40px;
        height: auto;
        
    }

    .newUser {
        margin-bottom: 350px;
    }
 }

 @media screen and (max-width: 575px) {
     
     .graphic {
        width: auto;
        height: 450px;
        padding: 5%;
        transition-duration: .5s; 
    }
    
    .graphic:hover {
       height: 500px;
    }

    
    

    .home-form {
        align-items: center;
        padding-left: 8%;
        padding-bottom: 18%;
        padding-top: 5%;
        margin-right: 0 !important;
        margin-left: 0 !important;
        overflow-x: hidden;
    }

    .form {
        padding-right: 1%;
        padding-left: 1%;
    }

    .copyrightIcon {
        padding-bottom: 25px;
    }

    .login-input {
        margin-right:  -12px !important;
    }

    .astroForm {
        margin-left: 3% !important;
    }

    .example {
        height: 100px;
        width: 145px;
    }
 }

 .graphic-brand-col {
    margin-top: 8%;
    margin-bottom: 12%;
}

.graphic-brand-title {
    margin-bottom: 2%;
    font-size: 40px;
    font-weight: 900;
    color: #b2b2cc;
    padding-left: 1%;
    text-decoration: underline #f7896b;
}

.graphic-brand-text {
    color: #b2b2cc;
    font-size: 20px;
    font-weight: 300;
    padding-right: 7%;
    padding-left: 2%;
}

.delete-bottom {
    margin-bottom: -120px;
}

.why-astro-graphics-row {

}

.why-graphic-text {
    margin-left: 7%;
    margin-right: 7%;
    font-size: 20px;
}

@media screen and (max-width:1000px){
    .graphic-brand-col {
        margin-left: 5%;
    }
}

.forgot-a {
    color: #f7896b !important;
}

.sign-a {
    color: #6464a0 !important;
}

.login-as-row {
 margin-top: 5% !important;
 margin-bottom: 5% !important;
}

.login-btns {
    padding-top: 3%;
    padding-bottom: 3%;
}

.login-btns:hover {
    color: #b2b2cc !important;
}