.home-page {
  width: 100%;
  
}

.outerParallax {
    top: -100px;
    height: 850px;
    margin-bottom: -100px;
    overflow: hidden;
}

.outerParallax-left {
  transform: rotate(-90deg) !important;
  height: 400px;
  width: 500px !important;
  overflow: visible !important;
}

.graphic-left {
  height: 400px;
}

.parallax-left {
  transform: rotate(90deg) !important;
}

.outerParallax-left-small {
transform: rotate(-90deg) !important;
height: 300px;
width: 600px;
overflow: visible !important;
}

.graphic-left-small {
height: 300px !important;
width: 300px !important;
}

.parallax-left-small {
  transform: rotate(90deg) !important;
  color: white;
}

.outerParallax-right {
  transform: rotate(90deg) !important;
  height: 400px;
  width: 500px !important;
  overflow: visible !important;
}

.graphic-right {
  height: 400px;
}

.parallax-right {
  transform: rotate(-90deg) !important;
}

.outerParallax-web {
  transform: rotate(-90deg) !important;
  height: 475px;
  overflow: visible !important;
  margin-left: -50px;
}

.graphic-web {
  height: 345px;
  margin-bottom: 50px;
  margin-top: 50px;
  border-radius: 30px;
}

.parallax-web {
  transform: rotate(90deg) !important;
}

.outerParallax-web-right {
  transform: rotate(90deg) !important;
  height: 475px;
  overflow: visible !important;
  margin-right: -50px;
}

.graphic-web-right {
  height: 345px;
  margin-bottom: 50px;
  margin-top: 50px;
  border-radius: 30px;
}

.parallax-web-right {
  transform: rotate(-90deg) !important;
}

.outerParallax-right-graphic {
  transform: rotate(90deg) !important;
  height: 600px;
  width: 400px;
  overflow: visible !important;
  }
  
  .graphic-right-graphic {
  height: 600px;
  }
  
  .parallax-right-graphic {
    transform: rotate(-90deg) !important;
  }

.parallax {
  width: 100%;
  height: 800px;
  background-color: #1e1e3f;
}

.parallax-social {
  width: 100%;
  background-color: #1e1e3f !important;
}

.parallax-service {
  width: 100% !important;
 top: -100px !important;
}

.parallax-service .react-parallax-bgimage {
  filter: blur(5px) !important;
  -webkit-filter: blur(5px) !important;
}

.service-page {
  width: 100% !important;
  background-color: #201231;
  margin-bottom: -100px;
}

@media screen and (max-width: 600px) {
  .parallax {
    width: 100%;
    height: 850px;
    
  }

  .outerParallax-right-graphic {
    width: 400px;
    margin-left: -30px !important;
   
    }
}

.astroLogo-ul {
  padding-left: 0px !important;
}
.homeLogo {
  margin-top: 300px;
  height: 300px;
  margin-bottom: 30% !important;
}

.background-blue {
  margin-top: -100px;
  height: 100px;
  background-color: #6464a0;
}

.background-blue-2 {
  height: 100px;
  background-color: #3b3b5e;
}

.background-blue-3 {
  margin-top: -100px;
  height: 100px;
  background-color: #3b3b5e;
}

.background-blue-4 {
  height: 100px;
  background-color: #6464a0;
}

.background-blue-5 {
  height: 100px;
  background-color: #b1b1c5;
}

.background-blue-6 {
  height: 100px;
  background-color: #8a8ab3;
}

.background-blue-7 {
  height: 100px;
  background-color: #525280;
}

.background-blue-10 {
  height: 150px;
  background: linear-gradient(to bottom, #e6e6e6 0%, #b2b2cc 100%);
}

.background-blue-11 {
  height: 150px;
  background: linear-gradient(to bottom, #201f38 0%, #e6e6e6 100%);
}

.background-blue-12 {
  height: 150px;
  background: linear-gradient(to bottom, white 10%, #6464a0 100%);
}

.background-blue-13 {
  height: 150px;
  background: linear-gradient(to bottom, #6464a0 10%, #201f38 100%);
}

.background-blue-14 {
  height: 150px;
  background: linear-gradient(to bottom, #6464a0 10%, #434275 100%);
}

.background-blue-15 {
  height: 150px;
  background: linear-gradient(to bottom, #434275 10%, #302f55 100%);
}

.background-blue-16 {
  height: 150px;
  background: linear-gradient(to bottom, #302f55 10%, #201f38 100%);
}

.icon {
  width: 150px;
  margin: 7%;
}

.icon:hover {
  width: 155px;
  margin: 7%;
}

.ul-icon {
  padding-top: 10%;
  padding-bottom: 7%;
}

.ul-icon-contact {
  padding-top: 10%;
  padding-bottom: 7%;
}  


@media screen and (max-width: 1000px) {
  .ul-icon-contact {
    padding-top: 25%;
    padding-bottom: 20%;
  }  
  .outerParallax-right-graphic {
    transform: rotate(90deg) !important;
    height: 600px;
    width: 400px;
    margin-left: 200px;
    overflow: visible !important;
    }
}