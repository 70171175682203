.adminMenu {
    background-color: #293241;
    margin:20px;
    color: white; 
    border-radius: 15px;
    padding: 15px 12px;  
}

.menuIcon{
    color: #ee6c4d;
}

.exTr {
   padding: 10%;
}

.exTable {
    margin-left: 7%;
    width: 90%;
}

.iconlink {
    color: #ee6c4d;
}

.menuDesc {
    color: #c7d6d5;
}

.menuFilterButtons {
    margin-bottom: 5%;
    margin-top: 2%;
    margin-left: 3%;
}

.newExBtn {
    padding: 3%;
    background-color: #e0fbfc;
}

.select {
    margin-top: 7%;
    padding: 2%;
    width: 80%;
    height: 55%;
}

.userFormInput {
    color: white
}

@media screen and (max-width: 1000px) {
    .adminMenu {
        font-size: 12px;
        margin-top: 5%;
        margin-bottom: 5%;
        margin-left: 1%;
        margin-right: 1%;
        padding: 0%;
    }
}