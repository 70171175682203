.oneIcon {
    width: 150px !important;
    height: 150px !important;
    position: absolute;
    color: #231241;
    z-index: 0 !important;
}

.social-text-row {
    z-index: 5 !important;
}

.floatParallax-left {
    
    transform: rotate(90deg) !important;
    overflow: visible !important;
    margin-left: -300px;
    z-index: 0 !important;
    height: 200px !important;
    margin-top: -100px;
    margin-bottom: -300px;
}

.floatP-left {
 width: 200px;
    transform: rotate(-90deg) !important;
    z-index: 0 !important;
    
}

.floatParallax-right {
    
    transform: rotate(-90deg) !important;
    overflow: visible !important;
    margin-left: 200px;
    z-index: 0 !important;
    height: 200px !important;
    margin-top: 100px;
    
   
}

.floatP-right {
   
    transform: rotate(90deg) !important;
    z-index: 0 !important;
    
}

.astroFacts-row {
}

.astroFacts-img {
    height: 400px;
    width: 500px;
}

.social-graphic-btn {
    margin-top: 100px;
    margin-bottom: -100px;
}

.facts-row {
    overflow-y: hidden !important;
   
    padding-bottom: 70px;
}

@media screen and (max-width:640px) {
    .floatParallax-left {
   
    
        margin-left: -320px;
        
    }
    .facts-row {
        padding-left: 0px !important;
    }
   
}

@media screen and (max-width:400px) {
    .floatParallax-left {
        margin-left: -460px;
        
    }
   
}

.socialmedia-page {
    overflow: hidden !important;
}



